// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";
import { es } from "vuetify/locale";

export default createVuetify({
  locale: {
    locale: "es",
    messages: { es },
  },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
