import { defineStore } from "pinia";
import { ref } from "vue";
import { useRouter } from "vue-router";

export const useSessionStore = defineStore("session", () => {
  const token = ref(null);
  const _user = ref({});
  const preadmission = ref({});
  const someError = ref(null);
  const router = useRouter();

  const login = async (user) => {
    try {
      const res = await fetch(
        "https://backsigede.puertocoquimbo.com/api/v1/auth/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        }
      );

      const resJSON = await res.json();

      if (resJSON.error) {
        someError.value = resJSON.error;
        return;
      } else if (resJSON.data) {
        token.value = resJSON.data.token;
        await ping();

        const expirationTime = 1.5 * 60 * 60 * 1000;

        setTimeout(() => {
          logout();
        }, expirationTime);

        await ping();
        if (_user.value.user_status === true)
          localStorage.setItem("token", resJSON.data.token);
        else {
          someError.value = "USER_NOT_EXIST";
        }
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkPreadmission = async (email) => {
    try {
      const res = await fetch(
        "https://backsigede.puertocoquimbo.com/api/v1/auth/preadmission",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(email),
        }
      );

      const resJSON = await res.json();

      if (resJSON.error) {
        someError.value = resJSON.error;
        return;
      } else if (resJSON.user) {
        preadmission.value = resJSON.user;
        return;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ping = async () => {
    try {
      if (!token.value) getToken();
      const res = await fetch(
        "https://backsigede.puertocoquimbo.com/api/v1/auth/ping",
        {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        }
      );

      const resJSON = await res.json();
      if (resJSON.ok) {
        _user.value = resJSON.body;
      } else logout();
    } catch (error) {
      console.log(error);
    }
  };

  const getToken = () => {
    if (localStorage.getItem("token")) {
      token.value = localStorage.getItem("token");
    } else {
      token.value = null;
      logout();
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    token.value = null;
    router.push("/");
  };

  return {
    token,
    _user,
    preadmission,
    someError,
    login,
    checkPreadmission,
    ping,
    getToken,
    logout,
  };
});
