import { defineStore } from "pinia";
import { ref } from "vue";
import { useSessionStore } from "../stores/session.js";

export const usePreAdmissionStore = defineStore("preadmissions", () => {
  const preadmissions = ref([]);

  const useSession = useSessionStore();
  const { getToken } = useSession;

  const getPreadmissions = async () => {
    getToken();
    try {
      const res = await fetch(
        "https://backsigede.puertocoquimbo.com/api/v1/preadmission",
        {
          headers: {
            Authorization: `Bearer ${useSession.token}`,
          },
        }
      );
      const resJSON = await res.json();
      preadmissions.value = resJSON.body;
    } catch (error) {
      console.log(error);
    }
  };

  const getPreadmission = async (preadmission_id) => {
    try {
      const res = await fetch(
        `https://backsigede.puertocoquimbo.com/api/v1/preadmission/${preadmission_id}`,
        {
          method: "GET",
        }
      );
      const resJSON = await res.json();
      return resJSON.body;
    } catch (error) {
      console.log(error);
    }
  };

  const createPreAdmission = async (preadmission) => {
    getToken();
    try {
      const res = await fetch(
        "https://backsigede.puertocoquimbo.com/api/v1/preadmission",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${useSession.token}`,
          },
          body: JSON.stringify(preadmission),
        }
      );
      await getPreadmissions();
    } catch (error) {
      console.log(error);
    }
  };

  const deletePreadmission = async (preadmission_id) => {
    getToken();
    try {
      await fetch(
        `https://backsigede.puertocoquimbo.com/api/v1/preadmission/${preadmission_id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${useSession.token}`,
          },
        }
      );
      await getPreadmissions();
    } catch (error) {
      console.log(error);
    }
  };

  return {
    preadmissions,
    getPreadmissions,
    getPreadmission,
    createPreAdmission,
    deletePreadmission,
  };
});
