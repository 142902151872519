import { defineStore } from "pinia";
import { ref } from "vue";

import { useSessionStore } from "../stores/session.js";

export const useUserRolesStore = defineStore("user_roles", () => {
  const user_roles = ref([]);
  const all_user_roles = ref([]);

  const useSession = useSessionStore();
  const { getToken, ping } = useSession;

  const getAllUserRoles = async () => {
    try {
      getToken();
      const res = await fetch(
        "https://backsigede.puertocoquimbo.com/api/v1/user-roles",
        {
          headers: {
            Authorization: `Bearer ${useSession.token}`,
          },
        }
      );
      const resJSON = await res.json();
      all_user_roles.value = resJSON.body;
    } catch (error) {
      console.log(error);
    }
  };

  const getUserRoles = async (user_id) => {
    try {
      getToken();
      const res = await fetch(
        `https://backsigede.puertocoquimbo.com/api/v1/user-roles/${user_id}`,
        {
          headers: {
            Authorization: `Bearer ${useSession.token}`,
          },
        }
      );
      const resJSON = await res.json();
      user_roles.value = resJSON.body;
    } catch (error) {
      console.log(error);
    }
  };

  const createUserRole = async (user_role) => {
    try {
      const res = await fetch(
        "https://backsigede.puertocoquimbo.com/api/v1/user-roles",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user_role),
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const deleteUserRoles = async (user_id) => {
    getToken();
    try {
      await fetch(
        `https://backsigede.puertocoquimbo.com/api/v1/user-roles/${user_id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${useSession.token}`,
          },
        }
      );
      await getAllUserRoles();
    } catch (error) {
      console.log(error);
    }
  };

  return {
    user_roles,
    all_user_roles,
    getAllUserRoles,
    getUserRoles,
    createUserRole,
    deleteUserRoles,
  };
});
